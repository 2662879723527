<template>
  <section class="features-layout2 pt-130 bg-overlay bg-overlay-primary">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-8 offset-lg-1">
          <div class="heading__layout2 mb-50">
            <h3 class="heading__title color-white">
              LAYANAN RSU WIRADADI HUSADA
            </h3>
          </div>
        </div>
      </div>
      <div class="row" v-if="posts.length > 0">
        <div
          class="col-sm-6 col-md-6 col-lg-3"
          v-for="post in posts"
          :key="post.id_layanan"
        >
          <div class="feature-item">
            <div class="feature__content">
              <div class="feature__icon">
                <i class="icon-heart"></i>
              </div>
              <h4 class="feature__title">{{ post.headline }}</h4>
            </div>
            <a href="/layanan" class="btn__link">
              <i class="icon-arrow-right icon-outlined"></i>
            </a>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="row">
          <div
            class="col-md-4 mb-3"
            v-for="loader in posts_loader"
            :key="loader"
          >
            <div class="card border-0 shadow-sm rounded-lg">
              <div class="card-body">
                <FacebookLoader />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { FacebookLoader } from "vue-content-loader";

import axios from "axios";

import { ref, onMounted } from "vue";

export default {
  name: "LayananRumahsakit",

  components: {
    FacebookLoader,
  },

  setup() {
    const posts = ref([]);
    const posts_loader = ref(6);

    let moreExists = ref(false);
    let nextPage = ref(0);

    const fetchDataPosts = () => {
      axios
        .get("https://www.webadmin.rsuwiradadihusada.co.id/api/layanan")
        .then((response) => {
          posts.value = response.data.data;

          if (response.data.data.current_page < response.data.data.last_page) {
            moreExists.value = true;

            nextPage.value = response.data.data.current_page + 1;
          } else {
            moreExists.value = false;
          }
        });
    };

    //define method loadMore
    const loadMore = () => {
      axios
        .get(
          `https://www.webadmin.rsuwiradadihusada.co.id/api/layanan?page=${nextPage.value}`
        )
        .then((response) => {
          if (response.data.data.current_page < response.data.data.last_page) {
            //set state moreExists to true
            moreExists = true;

            //increment nextPage
            nextPage = response.data.data.current_page + 1;
          } else {
            //set state moreExists to false
            this.moreExists = false;
          }

          //assign response to state posts
          response.data.data.data.forEach((data) => {
            posts.value.push(data);
          });
        });
    };

    //run hook onMounted
    onMounted(() => {
      //fetch data posts
      fetchDataPosts();
    });

    //return data
    return {
      posts,
      posts_loader,
      moreExists,
      nextPage,
      loadMore,
    };
  },
};
</script>
