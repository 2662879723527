<template>
  <section class="blog-grid">
    <div class="container">
      <div class="row" v-if="loading">
        <LoadingLayanan />
      </div>
      <div class="row" v-else>
        <div
          class="col-sm-12 col-md-6 col-lg-4"
          v-for="lays in lay"
          :key="lays.id_layanan"
        >
          <div class="post-item">
            <div class="post__img">
              <a href="#">
                <vue-load-image>
                  <template v-slot:image>
                    <img
                      :src="
                        'https://www.webadmin.rsuwiradadihusada.co.id/layanan/' +
                        lays.gambar
                      "
                    />
                  </template>
                  <template v-slot:preloader>
                    <div class="col d-flex justify-content-center">
                      <img src="../assets/images/__Iphone-spinner-1.gif" />
                    </div>
                    <br />
                    <br /><br /><br />
                  </template>
                  <template v-slot:error>Image load fails</template>
                </vue-load-image>
              </a>
            </div>
            <div class="post__body">
              <div class="post__meta-cat">
                <router-link
                  :to="{
                    name: 'LayananDetail',
                    params: { slug: lays.slug },
                  }"
                >
                  <span>RSU Wiradadi Husada</span>
                </router-link>
              </div>

              <h4 class="post__title">
                {{ lays.headline }}
              </h4>
              <p
                class="post__desc"
                v-html="lays.deskripsi.substring(0, 150)"
              ></p>
              <router-link
                :to="{ name: 'LayananDetail', params: { slug: lays.slug } }"
                class="btn btn-warning btn__link btn__rounded"
              >
                <span>Lihat Selengkapnya</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import LoadingLayanan from "@/components/LoadingLayanan.vue";
import VueLoadImage from "vue-load-image";

export default {
  components: {
    LoadingLayanan,
    "vue-load-image": VueLoadImage,
  },

  data() {
    return {
      lay: [],
      loading: false,
    };
  },

  methods: {
    async getLay() {
      this.loading = true;
      try {
        let { data } = await axios.get(
          "https://www.webadmin.rsuwiradadihusada.co.id/api/layanan"
        );
        setTimeout(() => {
          this.lay = data.data;
          this.loading = false;
        }, 1000);
      } catch (e) {
        this.loading = true;
      }
    },
  },

  mounted() {
    this.getLay();
  },
};
</script>
