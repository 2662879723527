import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/LayoutView.vue";
import ProfilKami from "../views/ProfilView.vue";
import InformasiView from "../views/InformasiView.vue";
import SemuaDokter from "../views/DetailDokter.vue";
import BeritaTerkini from "../views/BeritaTerkini.vue";
import LayananWeb from "../views/LayananWeb.vue";
import DetailDokternya from "../views/DetailDokternya.vue";
import BeritaDetail from "../views/BeritaDetail.vue";
import KontakKami from "../views/KontakKami.vue";
import NotFound from "../views/NotFound.vue";
import LayananDetail from "../views/LayananDetail.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/profil",
    name: "profil",
    component: ProfilKami,
  },
  {
    path: "/informasi",
    name: "informasi",
    component: InformasiView,
  },
  {
    path: "/dokter",
    name: "dokter",
    component: SemuaDokter,
  },
  {
    path: "/dokter/:slug",
    name: "DetailDokternya",
    component: DetailDokternya,
  },
  {
    path: "/artikel",
    name: "artikel",
    component: BeritaTerkini,
  },
  {
    path: "/kontak",
    name: "kontak",
    component: KontakKami,
  },
  {
    path: "/artikel/:slug",
    name: "LihatDetail",
    component: BeritaDetail,
  },
  {
    path: "/layanan",
    name: "layanan",
    component: LayananWeb,
  },
  {
    path: "/layanan/:slug",
    name: "LayananDetail",
    component: LayananDetail,
  },
  {
    name: "NotFound",
    path: "/:pathMatch(.*)*",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
