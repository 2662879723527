<template>
  <section class="blog-grid">
    <div class="container">
      <div class="row" v-if="beritas.length > 0">
        <div
          class="col-sm-12 col-md-6 col-lg-4"
          v-for="berita in beritas"
          :key="berita.id"
        >
          <div class="post-item">
            <div class="post__img">
              <vue-load-image>
                <template v-slot:image>
                  <img
                    :src="
                      'https://www.webadmin.rsuwiradadihusada.co.id/berita/' +
                      berita.gambar
                    "
                  />
                </template>
                <template v-slot:preloader>
                  <div class="col d-flex justify-content-center">
                    <img src="../assets/images/__Iphone-spinner-1.gif" />
                  </div>
                </template>
                <template v-slot:error>Image load fails</template>
              </vue-load-image>
            </div>
            <br /><br />
            <div class="post__body">
              <div class="post__meta-cat">
                <a href="#">RSU Wiradadi Husada</a>
              </div>

              <h4 class="post__title">
                {{ berita.judul }}
              </h4>

              <p class="post__desc" v-html="berita.isi.substring(0, 100)"></p>
              <router-link
                :to="{ name: 'LihatDetail', params: { slug: berita.slug } }"
                class="btn btn-warning btn__link btn__rounded"
              >
                <span>Baca Selengkapnya</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-else>
        <LoadingLayanan />
      </div>

      <div class="row">
        <div class="col-12 text-center">
          <nav class="pagination-area">
            <div
              class="d-flex justify-content-center mt-4"
              v-if="filters.page < lastPage"
            >
              <button class="btn btn-warning" @click="loadMore">
                Lihat Artikel Selanjutnya
              </button>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import LoadingLayanan from "@/components/LoadingLayanan.vue";
import VueLoadImage from "vue-load-image";

export default {
  components: {
    LoadingLayanan,
    "vue-load-image": VueLoadImage,
  },

  name: "ProductsDetail",

  props: ["beritas", "filters", "lastPage"],
  emits: ["set-filters"],

  setup(props, context) {
    const search = (s) => {
      context.emit("set-filters", {
        ...props.filters,
        s,
        page: 1,
      });
    };

    const sort = (sort) => {
      context.emit("set-filters", {
        ...props.filters,
        sort,
        page: 1,
      });
    };

    const loadMore = () => {
      context.emit("set-filters", {
        ...props.filters,
        page: props.filters.page + 1,
      });
    };

    return {
      search,
      sort,
      loadMore,
    };
  },
};
</script>
