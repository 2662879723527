<template>
  <Products
    :beritas="filteredProducts"
    :filters="filters"
    @set-filters="filtersChanged"
    :lastPage="lastPage"
  />
</template>

<script>
import Products from "@/components/BeritaComponent.vue";
import { onMounted, reactive, ref } from "vue";

export default {
  name: "FrontendWeb",
  components: { Products },
  setup() {
    const allProducts = ref([]);
    const filteredProducts = ref([]);
    const filters = reactive({
      s: "",
      sort: "",
      page: 1,
    });
    const perPage = 6;
    const lastPage = ref(0);

    onMounted(async () => {
      const response = await fetch(
        "https://www.webadmin.rsuwiradadihusada.co.id/api/berita"
      );

      const content = await response.json();

      allProducts.value = content.data;
      filteredProducts.value = content.data.slice(0, filters.page * perPage);
      lastPage.value = Math.ceil(content.data.length / perPage);
    });

    const filtersChanged = (f) => {
      filters.s = f.s;
      filters.sort = f.sort;
      filters.page = f.page;

      let products = allProducts.value.filter(
        (p) =>
          p.judul.toLowerCase().indexOf(filters.s.toLowerCase()) >= 0 ||
          p.headline.toLowerCase().indexOf(filters.s.toLowerCase()) >= 0
      );

      if (filters.sort === "asc" || filters.sort === "desc") {
        products.sort((a, b) => {
          const diff = a.price - b.price;

          if (diff === 0) return 0;

          const sign = Math.abs(diff) / diff; // -1, 1

          return filters.sort === "asc" ? sign : -sign;
        });
      }

      lastPage.value = Math.ceil(products.length / perPage);
      filteredProducts.value = products.slice(0, filters.page * perPage);
    };

    return {
      filteredProducts,
      filters,
      lastPage,
      filtersChanged,
    };
  },
};
</script>
