<template>
  <SliderPages />
  <section class="contact-info py-0">
    <div class="container">
      <div class="row row-no-gutter boxes-wrapper">
        <div class="col-sm-12 col-md-4">
          <div class="contact-box d-flex">
            <div class="contact__icon">
              <i class="icon-call3"></i>
            </div>
            <div class="contact__content">
              <h2 class="contact__title">Emergency Call</h2>
              <p class="contact__desc">
                Apabila keluarga dan kerabat anda memerlukan layanan
                emergency,kami siap melayani anda dalam situasi darurat.
              </p>
              <a href="tel:+62816846225" class="phone__number">
                <i class="icon-phone"></i> <span>(0281) 6846225</span>
              </a>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-4">
          <div class="contact-box d-flex">
            <div class="contact__icon">
              <i class="icon-heart2"></i>
            </div>
            <div class="contact__content">
              <h2 class="contact__title">Media Pendaftaran Online</h2>
              <ul class="time__list list-unstyled mb-0">
                <li>
                  <a
                    href="https://play.google.com/store/apps/details?id=co.id.rsuwiradadihusada.antrian_wiradadi&hl=en_US"
                    class="btn btn__white btn__outlined btn__rounded"
                    target="_blank"
                  >
                    <span>RSWH Mobile</span><i class="icon-arrow-right"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://play.google.com/store/apps/details?id=app.bpjs.mobile&hl=en_US"
                    class="btn btn__white btn__outlined btn__rounded"
                    target="_blank"
                  >
                    <span>Mobile JKN</span><i class="icon-arrow-right"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://bit.ly/daftarrsuwh"
                    class="btn btn__white btn__outlined btn__rounded"
                    target="_blank"
                  >
                    <span>Melalui Website</span><i class="icon-arrow-right"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="col-sm-12 col-md-4">
          <div class="contact-box d-flex">
            <div class="contact__icon">
              <i class="icon-health-report"></i>
            </div>
            <div class="contact__content">
              <h2 class="contact__title">Jadwal Dokter</h2>
              <p class="contact__desc">
                Temukan jadwal dokter penyakit dalam, jadwal dokter gigi, serta
                jadwal dokter lainnya.
              </p>
              <a
                href="/dokter"
                class="btn btn__white btn__outlined btn__rounded"
              >
                <span>Lihat Dokter</span><i class="icon-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="about-layout2 pb-0">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-7 offset-lg-1">
          <div class="heading-layout2">
            <h3 class="heading__title mb-60">
              Kesembuhan Anda Adalah <br />
              Tujuan Dan Kebahagian Kami.
            </h3>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-5">
          <div class="text-with-icon">
            <div class="text__icon">
              <i class="icon-doctor"></i>
            </div>
            <div class="text__content">
              <p class="heading__desc font-weight-bold color-secondary mb-30">
                Melayani dengan standar kualitas terbaik, dokter & tenaga medis
                profesional serta fasilitas lengkap untuk memastikan Anda
                mendapat perawatan terbaik sesuai kebutuhan Anda..
              </p>
            </div>
          </div>
          <div class="videowrapper">
            <iframe
              width="680"
              height="315"
              src="https://www.youtube.com/embed/kJIk2tUZNOw?si=OyII6Lo2whUnUdtf"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-7">
          <div class="about__text bg-white">
            <br />
            <h5 class="contact__title">Fasilitas & Layanan Kami</h5>
            <ul class="list-items list-unstyled">
              <li>IGD 24 Jam.</li>
              <li>Konsultasi dan Pemeriksaan Dokter Spesialis.</li>
              <li>Pelayanan Rawat Inap dan Rawat Jalan.</li>
              <li>CT Scan.</li>
              <li>Surgical Staging.</li>
              <li>Rehabilitas Medik.</li>
              <li>Laboratorium.</li>
              <li>Ecokardiografi.</li>
              <li>Perinatologi.</li>
              <li>Farmasi.</li>
              <li>ESWL.</li>
              <li>Radiologi.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="team-layout2 pb-80">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-6 offset-lg-3">
          <div class="heading text-center mb-40">
            <h3 class="heading__title">Dokter Kami</h3>
            <p class="heading__desc">
              Temukan jadwal dokter jantung, jadwal dokter kandungan, jadwal
              dokter anak, jadwal dokter penyakit dalam, jadwal dokter gigi,
              serta jadwal dokter lainnya.
            </p>
          </div>
        </div>
      </div>
      <DokterWeb />
    </div>
  </section>

  <section class="features-layout2 pt-30 bg-overlay bg-overlay-primary">
    <div class="container">
      <LayananRumahsakit />
    </div>
  </section>

  <BeritaWeb />
  <section class="about-layout5">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-5">
          <div class="map-wrapper mb-0">
            <img
              class="rounded mx-auto d-block"
              :src="'https://www.webadmin.rsuwiradadihusada.co.id/layanan/rswhmobilekita.png'"
              alt="rswhmobile"
              height="550"
            />
          </div>
          <!-- /.map-wrapper -->
        </div>
        <!-- /.col-lg-6 -->
        <div class="col-sm-12 col-md-12 col-lg-6 offset-lg-1">
          <br />
          <h3 class="heading__title mb-20">
            Solusi kesehatan terintegrasi, dalam genggaman.
          </h3>
          <p class="heading__desc mb-30">
            Akses pelayanan kami dari ponsel Anda. Telusuri lebih lanjut. RSWH
            Mobile, aplikasi mobile yang memudahkan Anda dalam mengakses
            pelayanan kami mulai dari Pendaftaran Online, Jadwal Dokter, Mobile
            JKN, serta masih banyak fitur lainnya untuk dapat mempermudah
            pasien. Akses menggunakan ponsel pintar anda.
          </p>
          <ul class="list-items list-items-layout3 list-unstyled">
            <li>Cek Janji temu dan jadwal dokter.</li>
            <li>Pendaftaran Online.</li>
            <li>Informasi Kesehatan.</li>
          </ul>
          <br /><br />
          <div class="d-flex align-items-center mb-60">
            <a href="contact-us.html">
              <img
                :src="'https://www.webadmin.rsuwiradadihusada.co.id/layanan/playstore.png'"
                loading="lazy"
              />
            </a>
            &nbsp;
            <a href="contact-us.html">
              <img
                :src="'https://www.webadmin.rsuwiradadihusada.co.id/layanan/appstore.png'"
                loading="lazy"
              />
            </a>
          </div>
        </div>
        <!-- /.col-lg-6 -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.container -->
  </section>
  <!-- /.About Layout 5 -->
</template>

<script>
import SliderPages from "./pages/SliderPages.vue";
import DokterWeb from "@/components/DokterWeb.vue";
import BeritaWeb from "@/components/BeritaWeb.vue";
import LayananRumahsakit from "@/views/LayananRumahsakit.vue";

export default {
  name: "LayoutView",

  components: {
    SliderPages,
    DokterWeb,
    BeritaWeb,
    LayananRumahsakit,
  },
};
</script>

<style>
.videowrapper {
  float: none;
  clear: both;
  width: 100%;
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
}
.videowrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
