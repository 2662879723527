<template>
  <section class="blog-grid pb-10">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-4" v-for="p in post" :key="p.id">
          <div class="post-item">
            <div class="post__img">
              <img
                :src="
                  'https://www.webadmin.rsuwiradadihusada.co.id/dokter/' +
                  p.gambar
                "
                loading="lazy"
              />
            </div>
            <div class="post__body">
              <div class="post__meta-cat">
                <a href="/dokter">{{ p.spesialis }}</a>
              </div>

              <h4 class="post__title">
                <a href="/dokter">{{ p.namadokter }}</a>
              </h4>
            </div>
          </div>
        </div>
        <p class="text-justify">
          <a href="/dokter" class="btn btn-danger btn__link btn__rounded">
            <span>Lihat Semua Dokter</span>
            <i class="icon-arrow-right"></i>
          </a>
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      post: [],
    };
  },
  methods: {
    getPosts() {
      fetch("https://www.webadmin.rsuwiradadihusada.co.id/api/homepage/dokter")
        .then((response) => response.json())
        .then((data) => (this.post = data.data));
    },
  },
  mounted() {
    this.getPosts();
  },
};
</script>
