<template>
  <section class="team-layout3 pb-15">
    <div class="container" v-if="products.length">
      <div class="col-md-12 mb-4 input-group pb-20">
        <input
          class="form-control"
          placeholder="Cari Dokter"
          @keyup="search($event.target.value)"
        />
      </div>

      <div class="row">
        <div
          class="col-sm-6 col-md-4 col-lg-4"
          v-for="product in products"
          :key="product.id"
        >
          <div class="member">
            <div class="member__img">
              <vue-load-image>
                <template v-slot:image>
                  <img
                    :src="
                      'https://www.webadmin.rsuwiradadihusada.co.id/dokter/' +
                      product.gambar
                    "
                  />
                </template>
                <template v-slot:preloader>
                  <div class="col d-flex justify-content-center">
                    <img src="../assets/images/__Iphone-spinner-1.gif" />
                  </div>
                </template>
                <template v-slot:error>Image load fails</template>
              </vue-load-image>
            </div>
            <div class="member__info">
              <h5 class="member__name">
                <router-link
                  :to="{
                    name: 'DetailDokternya',
                    params: { slug: product.slug },
                  }"
                >
                  {{ product.namadokter }}
                </router-link>
              </h5>
              <p class="member__job">{{ product.spesialis }}</p>
              <p
                class="member__desc"
                v-html="product.deskripsi?.substring(0, 150)"
              ></p>
              <br />
              <router-link
                :to="{
                  name: 'DetailDokternya',
                  params: { slug: product.slug },
                }"
                class="btn btn-warning btn__link btn__rounded"
              >
                <span>Detail Dokter</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <div
            class="d-flex justify-content-center mt-4"
            v-if="filters.page < lastPage"
          >
            <button class="btn btn-warning" @click="loadMore">
              Lihat Selanjutnya
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="container" v-else>
      <div class="row">
        <LoadingLayanan />
      </div>
    </div>
  </section>
</template>

<script>
import LoadingLayanan from "@/components/LoadingLayanan.vue";
import VueLoadImage from "vue-load-image";

export default {
  components: {
    LoadingLayanan,
    "vue-load-image": VueLoadImage,
  },

  name: "ProductsDetail",

  props: ["products", "filters", "lastPage"],
  emits: ["set-filters"],
  setup(props, context) {
    const search = (s) => {
      context.emit("set-filters", {
        ...props.filters,
        s,
        page: 1,
      });
    };

    const sort = (sort) => {
      context.emit("set-filters", {
        ...props.filters,
        sort,
        page: 1,
      });
    };

    const loadMore = () => {
      context.emit("set-filters", {
        ...props.filters,
        page: props.filters.page + 1,
      });
    };

    return {
      search,
      sort,
      loadMore,
    };
  },
};
</script>
