<template>
  <section class="loading">
    <span class="loadWords">
      <img src="../../public/assets/images/logo-mobile-rswh.png"
    /></span>
    <span class="loading__anim"></span>
    <span class="loadWords">silahkan tunggu...</span>
  </section>
</template>

<script>
export default {
  name: "CircularLoading",
};
</script>

<style>
.loading {
  position: relative;
  max-width: 100%;
  line-height: 1.4;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
}

.loadWords {
  font-weight: normal;
  font-size: 0.9rem;
  color: #000;
  margin: 0.6rem 0 1rem 0;
  display: block;
}

.loading__anim {
  width: 25px;
  height: 25px;
  border: 5px solid rgba(189, 189, 189, 0.25);
  border-left-color: #b20600;
  border-top-color: #b20600;
  border-radius: 50%;
  display: inline-block;
  animation: rotate 600ms infinite linear;
}

@keyframes rotate {
  to {
    transform: rotate(1turn);
  }
}
</style>
