<template>
  <section id="content" class="pb-80">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="text-block mb-50">
            <h2 class="text-block__title">
              Informasi Umum RSU Wiradadi Husada
            </h2>
            <br />
            <div id="accordion" class="mb-70">
              <div class="accordion-item opened">
                <div
                  class="accordion__header"
                  data-toggle="collapse"
                  data-target="#collapse3"
                >
                  <a class="accordion__title" href="#">Tata Tertib</a>
                </div>
                <div
                  id="collapse3"
                  class="collapse show"
                  data-parent="#accordion"
                >
                  <div class="accordion__body">
                    <div v-if="tatatertib.isi_tatatertib">
                      <div
                        class="post__desc"
                        v-html="tatatertib.isi_tatatertib"
                      ></div>
                    </div>
                    <div v-else>
                      <CircularLoading />
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <div
                  class="accordion__header"
                  data-toggle="collapse"
                  data-target="#collapse1"
                >
                  <a class="accordion__title" href="#"
                    >Pendaftaran Dan Asuransi</a
                  >
                </div>
                <div id="collapse1" class="collapse" data-parent="#accordion">
                  <br />
                  <div v-if="asuransi.isi_asuransi">
                    <div
                      class="post__desc"
                      v-html="asuransi.isi_asuransi"
                    ></div>
                  </div>
                  <div v-else>
                    <CircularLoading />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

import { ref, onMounted } from "vue";
import CircularLoading from "@/components/CircularLoading.vue";

export default {
  name: "InformasiView",

  components: {
    CircularLoading,
  },

  setup() {
    const tatatertib = ref([]);
    const asuransi = ref([]);

    onMounted(() => {
      setTimeout(() => {
        axios
          .get("https://www.webadmin.rsuwiradadihusada.co.id/api/tatatertib/1")
          .then((response) => {
            tatatertib.value = response.data.data;
          });
      }, 2000);

      setTimeout(() => {
        axios
          .get("https://www.webadmin.rsuwiradadihusada.co.id/api/asuransi/1")
          .then((response) => {
            asuransi.value = response.data.data;
          });
      }, 2000);
    });

    return {
      tatatertib,
      asuransi,
    };
  },
};
</script>
