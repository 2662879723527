<template>
  <section class="page-title pt-30 pb-30 text-center">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-12">
          <nav>
            <ol class="breadcrumb mb-0">
              <li class="breadcrumb-item"><a href="/">Home</a></li>
              <li class="breadcrumb-item"><a href="/layanan">Layanan</a></li>
              <li class="breadcrumb-item active" aria-current="page">
                {{ post.headline }}
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </section>

  <section class="blog blog-single pt-0 pb-80">
    <div class="container">
      <div class="row" v-if="post.headline">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="post-item mb-0">
            <div class="post__img">
              <a href="#">
                <img
                  v-if="post.gambar"
                  :src="
                    'https://www.webadmin.rsuwiradadihusada.co.id/layanan/' +
                    post.gambar
                  "
                />
                <img
                  v-else
                  :src="'https://www.webadmin.rsuwiradadihusada.co.id/layanan/layanankamirswh.jpg'"
                />
              </a>
            </div>
            <div class="post__body pb-0">
              <div class="post__meta-cat">
                <a href="#">RSU Wiradadi Husada</a>
              </div>
              <div class="post__meta d-flex align-items-center mb-20">
                <span class="post__meta-date">{{ post.tanggal }}</span>
                <a class="post__meta-author" href="#"
                  >Administrator | Layanan RSU WIRADADI HUSADA</a
                >
              </div>
              <h1 class="post__title mb-30">
                {{ post.headline }}
              </h1>
              <div class="post__desc" v-html="post.deskripsi"></div>
              <br />
              <div class="post__desc" v-html="post.isi_layanan"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-else>
        <LoadingLayananDetail />
      </div>
    </div>
  </section>
</template>

<script>
import LoadingLayananDetail from "@/components/LoadingDetailLayanan.vue";
import axios from "axios";

import { ref, onMounted } from "vue";

import { useRoute } from "vue-router";

export default {
  name: "LayananDetail",

  components: { LoadingLayananDetail },

  setup() {
    //define state
    const post = ref({});
    const route = useRoute();

    onMounted(() => {
      setTimeout(() => {
        axios
          .get(
            `https://www.webadmin.rsuwiradadihusada.co.id/api/layanan/${route.params.slug}`
          )
          .then((response) => {
            post.value = response.data.data;
          });
      }, 800);
    });

    return {
      post,
    };
  },
};
</script>
