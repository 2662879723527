<template>
  <div class="wrapper">
    <HeaderTop />
    <router-view />
    <FooterPages />
    <!-- <button id="scrollTopBtn">
      <i class="fas fa-long-arrow-alt-up"></i>
    </button> -->
    <a
      href="https://api.whatsapp.com/send?phone=
6281333000115"
      target="_blank">
      <button class="btn-floating whatsapp">
        <img
          src="../public/assets/images/whatsapp.png"
          height="30"
          alt="whatsApp" />
        <span>RSU Wiradadi Husada</span>
      </button>
    </a>
    <a
      href="https://www.facebook.com/RumahSakitUmumWiradadiHusada/"
      target="_blank">
      <button class="btn-floating facebook">
        <img
          src="../public/assets/images/facebook.png"
          height="30"
          alt="facebook" />
        <span>RSU Wiradadi Husada</span>
      </button>
    </a>
    <a href="https://www.instagram.com/rsu.wiradadihusada/" target="_blank">
      <button class="btn-floating instagram">
        <img
          src="../public/assets/images/instagram.png"
          height="30"
          alt="instagram" />
        <span>RSU Wiradadi Husada</span>
      </button>
    </a>
  </div>
</template>

<script>
import HeaderTop from "./views/pages/HeaderTop.vue";
import FooterPages from "./views/pages/FooterPages.vue";

export default {
  components: { HeaderTop, FooterPages },
};
</script>

<style>
.btn-floating {
  position: fixed;
  right: 25px;
  overflow: hidden;
  width: 50px;
  height: 50px;
  border-radius: 100px;
  border: 0;
  z-index: 9999;
  color: white;
  transition: 0.2s;
}

.btn-floating:hover {
  width: auto;
  padding: 0 20px;
  cursor: pointer;
}

.btn-floating span {
  font-size: 16px;
  margin-left: 5px;
  transition: 0.2s;
  line-height: 0px;
  display: none;
}

.btn-floating:hover span {
  display: inline-block;
}

.btn-floating:hover img {
  margin-bottom: -3px;
}

.btn-floating.whatsapp {
  bottom: 85px;
  background-color: #34af23;
  border: 2px solid #fff;
}

.btn-floating.whatsapp:hover {
  background-color: #1f7a12;
}

.btn-floating.facebook {
  bottom: 25px;
  background-color: #1876f3;
  border: 2px solid #fff;
}

.btn-floating.facebook:hover {
  background-color: #1876f3;
}

.btn-floating.instagram {
  bottom: 145px;
  background-color: #d80032;
  border: 2px solid #fff;
}

.btn-floating.instagram:hover {
  background-color: #d80032;
}
</style>
