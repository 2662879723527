<template>
  <section class="page-title pt-30 pb-30 text-center">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-12">
          <nav>
            <ol class="breadcrumb mb-0">
              <li class="breadcrumb-item"><a href="/">Home</a></li>
              <li class="breadcrumb-item"><a href="/artikel">Artikel</a></li>
              <li class="breadcrumb-item active" aria-current="page">
                {{ post.judul }}
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </section>

  <section class="blog blog-single pt-0 pb-80">
    <div class="container">
      <div class="row" v-if="post.judul">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="post-item mb-0">
            <div class="post__img">
              <a href="#">
                <img
                  :src="
                    'https://www.webadmin.rsuwiradadihusada.co.id/berita/' +
                    post.gambar
                  "
                  loading="lazy"
                />
              </a>
            </div>
            <div class="post__body pb-0">
              <div class="post__meta-cat">
                <a href="#">RSU Wiradadi Husada</a>
              </div>
              <div class="post__meta d-flex align-items-center mb-20">
                <span class="post__meta-date">{{ post.tanggal }}</span>
                <a class="post__meta-author" href="#">Administrator</a>
              </div>
              <h1 class="post__title mb-30">
                {{ post.judul }}
              </h1>
              <div class="post__desc" v-html="post.isi"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-else><LoadingLayananDetail /></div>
    </div>
  </section>
</template>

<script>
import LoadingLayananDetail from "@/components/LoadingDetailLayanan.vue";

//import axios
import axios from "axios";

//import hook onMounted from vue
import { ref, onMounted } from "vue";

//import hook useRoute
import { useRoute } from "vue-router";

export default {
  name: "PostDetailComponent",

  components: {
    LoadingLayananDetail,
  },

  setup() {
    //define state
    const post = ref({});
    const events = ref([]);
    const categories = ref([]);
    const events_loader = ref(2);
    const categories_loader = ref(1);

    //define route
    const route = useRoute();

    //run hook onMounted
    onMounted(() => {
      //get post detail

      setTimeout(() => {
        axios
          .get(
            `https://www.webadmin.rsuwiradadihusada.co.id/api/berita/${route.params.slug}`
          )
          .then((response) => {
            post.value = response.data.data;
          });
      }, 800);
    });

    return {
      post,
      events,
      categories,
      events_loader,
      categories_loader,
    };
  },
};
</script>
