<template>
  <div
    class="col-sm-12 col-md-6 col-lg-4"
    v-for="loader in posts_loader"
    :key="loader"
  >
    <div class="post-item">
      <div class="post__img">
        <div class="ssc-square mb"></div>   
      </div>
        <br />
        <h4 class="post__title"><div class="ssc-head-line w-50"></div></h4>
        <p class="post__desc"><div class="ssc-line w-90 mbs"></div></p>
        <p class="post__desc"><div class="ssc-line w-50 mbs"></div></p>
        <p class="post__desc"><div class="ssc-line w-30 mbs"></div></p>
        <p class="post__desc"><div class="ssc-line w-50 mbs"></div></p>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "LoadingLayanan",

  setup() {
    const posts_loader = ref(6);
    return {
      posts_loader,
    };
  },
};
</script>

<style>
.placeholderrr {
  background-color: #444;
  min-height: 12px;
  border-radius: 300px;
  opacity: 0.1;
  min-width: 5px;
  animation: fading 1.5s infinite;
}
.post {
  width: 300px;
  margin: 10%;
  padding: 10px 20px;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 2px 0 rgba(63, 63, 68, 0.15);
}
.post-imagesss {
  width: 200px;
  height: 50px;
  border-radius: 0;
}

.title {
  width: 400px;
  height: 18px;
}
.titlebawah {
  width: 150px;
  height: 18px;
}
.text {
  width: 100px;
  height: 14px;
}
.textpanjang {
  width: 300px;
  height: 14px;
}
.textpanjangsekali {
  width: 430px;
  height: 14px;
}
.status {
  width: 60px;
  height: 14px;
}
@keyframes fading {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.1;
  }
}
</style>
