<template>
  <section id="content" class="pb-80">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-6 offset-lg-3">
          <div class="heading text-center mb-60">
            <h2 class="heading__title">
              Profile Rumah Sakit Umum Wiradadi Husada.
            </h2>
          </div>
          <!-- /.heading -->
        </div>
        <!-- /.col-lg-6 -->
      </div>
      <!-- /.row -->
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="text-block mb-50">
            <div id="accordion" class="mb-70">
              <div class="accordion-item opened">
                <div
                  class="accordion__header"
                  data-toggle="collapse"
                  data-target="#collapse3"
                >
                  <a class="accordion__title" href="#">Tentang Kami</a>
                </div>
                <div
                  id="collapse3"
                  class="collapse show"
                  data-parent="#accordion"
                >
                  <div class="accordion__body">
                    <div v-if="profile.isiprofil">
                      <div class="post__desc" v-html="profile.isiprofil"></div>
                    </div>
                    <div v-else>
                      <CircularLoading />
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <div
                  class="accordion__header"
                  data-toggle="collapse"
                  data-target="#collapse1"
                >
                  <a class="accordion__title" href="#">Visi & Misi</a>
                </div>
                <div id="collapse1" class="collapse" data-parent="#accordion">
                  <div v-if="visi.isivisi">
                    <br />
                    <div class="post__desc" v-html="visi.isivisi"></div>
                  </div>
                  <div v-else>
                    <CircularLoading />
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <div
                  class="accordion__header"
                  data-toggle="collapse"
                  data-target="#collapse2"
                >
                  <a class="accordion__title" href="#">Struktur Organisasi</a>
                </div>
                <div id="collapse2" class="collapse" data-parent="#accordion">
                  <div class="accordion__body">
                    <div v-if="struktur.isi_struktur">
                      <div
                        class="post__desc"
                        v-html="struktur.isi_struktur"
                      ></div>
                    </div>
                    <div v-else>
                      <CircularLoading />
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <div
                  class="accordion__header"
                  data-toggle="collapse"
                  data-target="#collapse4"
                >
                  <a class="accordion__title" href="#">Indikator Mutu</a>
                </div>
                <div id="collapse4" class="collapse" data-parent="#accordion">
                  <div class="accordion__body">
                    <div v-if="indikator.isi_indikator">
                      <div
                        class="post__desc"
                        v-html="indikator.isi_indikator"
                      ></div>
                    </div>
                    <div v-else>
                      <CircularLoading />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

import { ref, onMounted } from "vue";
import CircularLoading from "@/components/CircularLoading.vue";

export default {
  name: "ProfilView",

  components: {
    CircularLoading,
  },

  setup() {
    const profile = ref([]);
    const visi = ref([]);
    const struktur = ref([]);
    const indikator = ref([]);

    onMounted(() => {
      setTimeout(() => {
        axios
          .get("https://www.webadmin.rsuwiradadihusada.co.id/api/profile/1")
          .then((response) => {
            profile.value = response.data.data;
          });
      }, 2000);

      setTimeout(() => {
        axios
          .get("https://www.webadmin.rsuwiradadihusada.co.id/api/visi/1")
          .then((response) => {
            visi.value = response.data.data;
          });
      }, 2000);

      axios
        .get("https://www.webadmin.rsuwiradadihusada.co.id/api/struktur/1")
        .then((response) => {
          struktur.value = response.data.data;
        });

      axios
        .get("https://www.webadmin.rsuwiradadihusada.co.id/api/indikator/1")
        .then((response) => {
          indikator.value = response.data.data;
        });
    });

    return {
      profile,
      visi,
      struktur,
      indikator,
    };
  },
};
</script>
