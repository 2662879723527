<template>
  <section class="google-map py-0">
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15824.221812599762!2d109.2718392!3d-7.4591178!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e655c13257608d5%3A0xecee4398dbcbbcdf!2sRSU%20Wiradadi%20Husada!5e0!3m2!1sid!2sid!4v1695694347939!5m2!1sid!2sid"
      width="100%"
      height="500"
      style="border: 0"
      allowfullscreen=""
      loading="lazy"
      referrerpolicy="no-referrer-when-downgrade"></iframe>
  </section>

  <section class="contact-layout1 pt-0 mt--100">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="contact-panel d-flex flex-wrap">
            <form
              class="contact-panel__form"
              method="post"
              action="#"
              id="contactForm">
              <div class="row">
                <div class="col-sm-12">
                  <h4 class="contact-panel__title">
                    Kirimkan Pesan Kepada Kami
                  </h4>
                  <p class="contact-panel__desc mb-30">
                    Silahkan tinggalkan pesan dibawah ini. Tim kami akan segera
                    meresponnya. Terima kasih.
                  </p>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6">
                  <div class="form-group">
                    <i class="icon-user form-group-icon"></i>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Nama Lengkap"
                      id="contact-name"
                      name="contact-name"
                      required />
                  </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6">
                  <div class="form-group">
                    <i class="icon-email form-group-icon"></i>
                    <input
                      type="email"
                      class="form-control"
                      placeholder="Email"
                      id="contact-email"
                      name="contact-email"
                      required />
                  </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6">
                  <div class="form-group">
                    <i class="icon-phone form-group-icon"></i>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="No. Telp"
                      id="contact-Phone"
                      name="contact-phone"
                      required />
                  </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6">
                  <div class="form-group">
                    <i class="icon-news form-group-icon"></i>
                    <select class="form-control">
                      <option value="0">Subject</option>
                      <option value="1">Subject 1</option>
                      <option value="2">Subject 1</option>
                    </select>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <i class="icon-alert form-group-icon"></i>
                    <textarea
                      class="form-control"
                      placeholder="Pesan Anda"
                      id="contact-message"
                      name="contact-message"></textarea>
                  </div>
                  <button
                    type="submit"
                    class="btn btn__secondary btn__rounded btn__block btn__xhight mt-10">
                    <span>Kirim Data</span>
                    <i class="icon-arrow-right"></i>
                  </button>
                  <div class="contact-result"></div>
                </div>
              </div>
            </form>
            <div
              class="contact-panel__info d-flex flex-column justify-content-between bg-overlay bg-overlay-primary-gradient">
              <div>
                <h4 class="contact-panel__title color-white">Hubungi Kami</h4>
                <p
                  class="contact-panel__desc font-weight-bold color-white mb-30">
                  Kami Siap Melayani Anda Selama 24 Jam Dalam Situasi Darurat.
                </p>
              </div>
              <div>
                <ul class="contact__list list-unstyled mb-30">
                  <li>
                    <i class="icon-phone"></i
                    ><a href="tel:+6281-6846225"
                      >Instalasi Gawat Darurat: (0281) 6846225</a
                    >
                  </li>
                  <li>
                    <i class="icon-phone"></i
                    ><a href="tel:+6281-6846225"
                      >Keluhan & Saran: 0813-3300-0115</a
                    >
                  </li>
                  <li>
                    <i class="icon-location"></i
                    ><a href="#"
                      >Lokasi Kami: Jl. Menteri Supeno No.25, Dusun I Wiradadi,
                      Wiradadi, Kec. Sokaraja, Kabupaten Banyumas,Jawa
                      Tengah.</a
                    >
                  </li>
                  <li>
                    <i class="icon-clock"></i
                    ><a href="contact-us.html"
                      >Waktu Layanan : Senin - Sabtu: 8:00 am - 7:00 pm</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

import { ref, onMounted } from "vue";

export default {
  name: "HomeComponent",

  setup() {
    const profile = ref([]);
    const visi = ref([]);
    const struktur = ref([]);
    const indikator = ref([]);

    onMounted(() => {
      setTimeout(() => {
        axios
          .get("https://www.webadmin.rsuwiradadihusada.co.id/api/profile/1")
          .then((response) => {
            profile.value = response.data.data;
          });
      }, 2000);

      setTimeout(() => {
        axios
          .get("https://www.webadmin.rsuwiradadihusada.co.id/api/visi/1")
          .then((response) => {
            visi.value = response.data.data;
          });
      }, 2000);

      axios
        .get("https://www.webadmin.rsuwiradadihusada.co.id/api/struktur/1")
        .then((response) => {
          struktur.value = response.data.data;
        });

      axios
        .get("https://www.webadmin.rsuwiradadihusada.co.id/api/indikator/1")
        .then((response) => {
          indikator.value = response.data.data;
        });
    });

    return {
      profile,
      visi,
      struktur,
      indikator,
    };
  },
};
</script>
