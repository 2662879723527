<template>

    <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="post-item mb-0">
            <div class="post__img">
              <div class="ssc-square w-90"></div>
            </div>
            <div class="post__body pb-0">
              <br>
              <div class="post__meta d-flex align-items-center mb-20">
                <span class="post__meta-date"></span>
                
              </div>
              <h1 class="post__title mb-30">
                <div class="ssc-head-line w-40"></div>
              </h1>
              <div class="post__desc">
                <p class="post__desc"><div class="ssc-line w-30 mbs"></div></p>
                <p class="post__desc"><div class="ssc-line w-30 mbs"></div></p>
                <p class="post__desc"><div class="ssc-line w-30 mbs"></div></p>
                <p class="post__desc"><div class="ssc-line w-30 mbs"></div></p>
                <p class="post__desc"><div class="ssc-line w-30 mbs"></div></p>
              </div>
              
            </div>
          </div>
        </div>
</template>

<script>

export default {
  name: "LoadingLayanan",
};
</script>

