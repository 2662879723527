<template>
  <header class="header header-layout1">
    <div class="header-topbar">
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-12">
            <div class="d-flex align-items-center justify-content-between">
              <ul
                class="contact__list d-flex flex-wrap align-items-center list-unstyled mb-0"
              >
                <li>
                  <button class="miniPopup-emergency-trigger">
                    <i class="icon-phone"></i>&nbsp;Emergency Call: (0281)
                    6846225
                  </button>
                </li>
                <li>
                  <i class="icon-location"></i
                  ><a href="https://goo.gl/maps/ifEsjmdYemSGf9kJ6"
                    >Lokasi: Sokaraja, Banyumas</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <nav class="navbar navbar-expand-lg sticky-navbar">
      <div class="container-fluid">
        <a class="navbar-brand" href="/">
          <img
            src="~@/assets/images/logo-mobile-rswh.png"
            class="logo-light"
            alt="logo"
          />
          <img
            src="~@/assets/images/logo-mobile-rswh.png"
            class="logo-dark"
            alt="logo"
          />
        </a>
        <button class="navbar-toggler" type="button">
          <span class="menu-lines"><span></span></span>
        </button>
        <div class="collapse navbar-collapse" id="mainNavigation">
          <ul class="navbar-nav ml-auto">
            <li class="nav__item has-dropdown">
              <a href="/" class="nav__item-link">Home</a>
            </li>
            <li class="nav__item has-dropdown">
              <a href="/profil" class="nav__item-link">Profil Kami</a>
            </li>
            <li class="nav__item has-dropdown">
              <a href="/layanan" class="nav__item-link">Layanan</a>
            </li>
            <li class="nav__item has-dropdown">
              <a href="/dokter" class="nav__item-link">Dokter</a>
            </li>

            <li class="nav__item has-dropdown">
              <a href="/informasi" class="nav__item-link">Informasi</a>
            </li>
            <!-- /.nav-item -->
            <li class="nav__item">
              <a href="/artikel" class="nav__item-link">Artikel</a>
            </li>
            <li class="nav__item">
              <a href="/kontak" class="nav__item-link">Kontak Kami</a>
            </li>
            <li class="nav__item">
              <a
                href="https://www.karir.rsuwiradadihusada.co.id/"
                class="nav__item-link"
                target="_blank"
                >Karir</a
              >
            </li>
            <!-- /.nav-item -->
          </ul>
          <!-- /.navbar-nav -->
          <button class="close-mobile-menu d-block d-lg-none">
            <i class="fas fa-times"></i>
          </button>
        </div>
        <!-- /.navbar-collapse -->
      </div>
      <!-- /.container -->
    </nav>
    <!-- /.navabr -->
  </header>
</template>
<script>
export default {
  data() {
    return {
      layanan: [],
    };
  },
  methods: {
    getPosts() {
      fetch("https://www.webadmin.rsuwiradadihusada.co.id/api/layanan")
        .then((response) => response.json())
        .then((data) => (this.layanan = data.data));
    },
  },
  mounted() {
    this.getPosts();
  },
};
</script>
