<template>
  <section class="pt-120 pb-80">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-4">
          <aside class="sidebar has-marign-right">
            <div class="widget widget-member">
              <div class="member mb-0">
                <div class="member__img">
                  <img
                    :src="
                      'https://www.webadmin.rsuwiradadihusada.co.id/dokter/' +
                      post.gambar
                    "
                  />
                </div>
                <div class="member__info">
                  <h5 class="member__name">
                    {{ post.namadokter }}
                  </h5>
                  <p class="member__job">{{ post.nama_poli }}</p>
                </div>
              </div>
            </div>
            <div class="widget widget-schedule">
              <div class="widget-content">
                <div class="widget__icon">
                  <i class="icon-charity2"></i>
                </div>
                <h4 class="widget__title">Jadwal Pelayanan</h4>
                <ul class="time__list list-unstyled mb-0">
                  <li v-for="jadwals in jadwal" :key="jadwals.id_jadwaldokter">
                    <span>{{ jadwals.hari }}</span
                    ><span>{{ jadwals.mulai }} - {{ jadwals.akhir }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </aside>
        </div>

        <div class="col-sm-12 col-md-12 col-lg-8">
          <div class="text-block mb-50">
            <br />
            <h5 class="text-block__title">Deskripsi</h5>
            <p class="text-block__desc mb-20" v-html="post.deskripsi"></p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { FacebookLoader, BulletListLoader } from "vue-content-loader";

import axios from "axios";

import { ref, onMounted } from "vue";

import { useRoute } from "vue-router";

export default {
  name: "DetailDokternya",

  components: {
    FacebookLoader,
    BulletListLoader,
  },

  setup() {
    const post = ref({});
    const jadwal = ref({});

    const route = useRoute();

    onMounted(() => {
      axios
        .get(
          `https://www.webadmin.rsuwiradadihusada.co.id/api/dokterkami/${route.params.slug}`
        )
        .then((response) => {
          post.value = response.data.data;
        });

      axios
        .get(
          `https://www.webadmin.rsuwiradadihusada.co.id/api/jadwal/${route.params.slug}`
        )
        .then((response) => {
          jadwal.value = response.data.data;
        });
    });

    return {
      post,
      jadwal,
    };
  },
};
</script>
