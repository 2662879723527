<template>
  <section class="slider">
    <div
      id="carouselExampleControls"
      class="carousel slide"
      data-ride="carousel"
    >
      <div class="carousel-inner">
        <div v-if="satu.gambar_slider">
          <div class="carousel-item active" data-interval="3000">
            <img
              :src="
                'https://www.webadmin.rsuwiradadihusada.co.id/slider/' +
                satu.gambar_slider
              "
              class="d-block w-100"
            />
          </div>
          <div class="carousel-item" data-interval="3000">
            <img
              :src="
                'https://www.webadmin.rsuwiradadihusada.co.id/slider/' +
                dua.gambar_slider
              "
              class="d-block w-100"
            />
          </div>
          <div class="carousel-item" data-interval="3000">
            <img
              :src="
                'https://www.webadmin.rsuwiradadihusada.co.id/slider/' +
                tiga.gambar_slider
              "
              class="d-block w-100"
            />
          </div>
        </div>
        <div v-else>
          <LoadingSlider />
        </div>
      </div>
      <a
        class="carousel-control-prev"
        href="#carouselExampleControls"
        role="button"
        data-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a
        class="carousel-control-next"
        href="#carouselExampleControls"
        role="button"
        data-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
  </section>
</template>
<script>
import axios from "axios";

import { ref, onMounted } from "vue";
import LoadingSlider from "@/components/LoadingSlider.vue";

export default {
  name: "SliderPages",

  components: {
    LoadingSlider,
  },

  setup() {
    const satu = ref([]);
    const dua = ref([]);
    const tiga = ref([]);

    onMounted(() => {
      setTimeout(() => {
        axios
          .get("https://www.webadmin.rsuwiradadihusada.co.id/api/slider/1")
          .then((response) => {
            satu.value = response.data.data;
          });
      }, 1000);

      axios
        .get("https://www.webadmin.rsuwiradadihusada.co.id/api/slider/2")
        .then((response) => {
          dua.value = response.data.data;
        });

      axios
        .get("https://www.webadmin.rsuwiradadihusada.co.id/api/slider/3")
        .then((response) => {
          tiga.value = response.data.data;
        });
    });

    return {
      satu,
      dua,
      tiga,
    };
  },
};
</script>
