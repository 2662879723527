<template>
  <section class="loading">
    <div class="center">
      <span class="loader"></span>
    </div>
  </section>
</template>

<style>
.loadinge {
  position: relative;
  max-width: 100%;
  line-height: 1.4;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
}
.center {
  margin: auto;
  width: 60%;
  padding: 120px;
}
.loadWords {
  font-weight: normal;
  font-size: 0.9rem;
  color: #000;
  margin: 0.6rem 0 1rem 0;
  display: block;
}
.loader {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: block;
  margin: 15px auto;
  position: relative;
  background: #b20600;
  box-sizing: border-box;
  animation: shadowExpandX 1s linear infinite alternate;
}

@keyframes shadowExpandX {
  0% {
    box-shadow: 0 0, 0 0;
    color: rgba(255, 255, 255, 0.2);
  }
  100% {
    box-shadow: -24px 0, 24px 0;
    color: #b20600;
  }
}
</style>
