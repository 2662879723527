<template>
  <footer class="footer">
    <div class="footer-primary">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-3">
            <div class="footer-widget-about">
              <img
                src="~@/assets/images/logorsuwiradadihusada.png"
                alt="rs wiradadi husada"
                class="mb-30"
              />
              <p class="color-white">
                Dengan tujuan untuk memberikan pelayanan kesehatan kepada
                seluruh lapisan masyarakat di Sokaraja pada khususnya dan
                Kabupaten Banyumas pada umumnya dalam rangka ikut membantu
                program pemerintah di bidang kesehatan..
              </p>
            </div>
          </div>
          <div class="col-sm-6 col-md-6 col-lg-2 offset-lg-1">
            <div class="footer-widget-nav">
              <h6 class="footer-widget__title">Main Menu</h6>
              <nav>
                <ul class="list-unstyled">
                  <li><a href="/" class="color-white">Home</a></li>
                  <li>
                    <a href="/profil" class="color-white">Profil Kami</a>
                  </li>
                  <li><a href="/layanan" class="color-white">Layanan</a></li>
                  <li><a href="/dokter" class="color-white">Dokter</a></li>
                  <li>
                    <a href="/informasi" class="color-white">Informasi</a>
                  </li>
                  <li>
                    <a href="/artikel" class="color-white">Artikel</a>
                  </li>
                  <li>
                    <a href="/kontak" class="color-white">Kontak</a>
                  </li>
                  <li>
                    <a
                      href="https://www.karir.rsuwiradadihusada.co.id/"
                      class="color-white"
                      target="_blank"
                      >Karir</a
                    >
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div class="col-sm-6 col-md-6 col-lg-2">
            <div class="footer-widget-nav">
              <h6 class="footer-widget__title">Terhubung Dengan Kami</h6>
              <nav>
                <ul class="list-unstyled">
                  <li>
                    <img
                      :src="'https://www.webadmin.rsuwiradadihusada.co.id/layanan/playstore.png'"
                      loading="lazy"
                      width="130"
                    />
                  </li>
                  &nbsp;
                  <li>
                    <img
                      :src="'https://www.webadmin.rsuwiradadihusada.co.id/layanan/appstore.png'"
                      loading="lazy"
                      width="130"
                    />
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4">
            <div class="footer-widget-contact">
              <h6 class="footer-widget__title color-heading">
                Layanan Pengaduan
              </h6>
              <ul class="contact-list list-unstyled">
                <li>
                  Unit Pengaduan Masyarakat (UPM) merupakan wadah resmi kepada
                  masyarakat untuk menyampaikan laporan, kritik maupun saran
                  perbaikan terhadap pelayanan di RSU Wiradadi Husada.
                </li>
                <li>
                  <a
                    href="https://wa.me/+6281333000115?text=Assalamu'alaikum.."
                    class="phone__number"
                  >
                    <i class="icon-phone"></i> <span>0813 3300 0115</span>
                  </a>
                </li>
              </ul>
              <div class="d-flex align-items-center">
                <ul class="social-icons list-unstyled mb-0">
                  <li>
                    <a
                      href="https://www.facebook.com/RumahSakitUmumWiradadiHusada"
                      ><i class="fab fa-facebook-f"></i
                    ></a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/rsu.wiradadihusada/"
                      ><i class="fab fa-instagram"></i
                    ></a>
                  </li>
                  <li>
                    <a href="#"><i class="fab fa-twitter"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-secondary">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-sm-12 col-md-6 col-lg-6">
            <span class="fz-14"
              >RSU Wiradadi Husada - Jl. Menteri Supeno No.25, Dusun I Wiradadi,
              Wiradadi, Kec. Sokaraja, Kabupaten Banyumas, Jawa Tengah 53181 -
              V.1.0</span
            >
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
