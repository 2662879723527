<template>
  <section class="blog-grid pb-50">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-6 offset-lg-3">
          <div class="heading text-center mb-40">
            <h2 class="heading__subtitle">Artikel Kesehatan</h2>
            <h3 class="heading__title">Berita Terbaru</h3>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-4" v-for="p in post" :key="p.id">
          <div class="post-item">
            <div class="post__img">
              <a href="/artikel">
                <img
                  :src="
                    'https://www.webadmin.rsuwiradadihusada.co.id/berita/' +
                    p.gambar
                  "
                  loading="lazy"
                />
              </a>
            </div>
            <div class="post__body">
              <div class="post__meta-cat">
                <a href="/artikel">Mental Health</a>
              </div>
              <div class="post__meta d-flex">
                <span class="post__meta-date">{{ p.tanggal }}</span>
                <a class="post__meta-author" href="#">Administrator</a>
              </div>
              <h4 class="post__title">
                <a href="/artikel">{{ p.judul }}</a>
              </h4>

              <p class="post__desc" v-html="p.isi.substring(0, 100)"></p>
              <a href="/artikel" class="btn btn-warning btn__link btn__rounded">
                <span>Baca Selengkapnya</span>
                <i class="icon-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
        <p class="text-justify">
          <a href="/artikel" class="btn btn-danger btn__link btn__rounded">
            <span>Lihat Semua Berita</span>
            <i class="icon-arrow-right"></i>
          </a>
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      post: [],
    };
  },
  methods: {
    getPosts() {
      fetch("https://www.webadmin.rsuwiradadihusada.co.id/api/homepage/post")
        .then((response) => response.json())
        .then((data) => (this.post = data.data));
    },
  },
  mounted() {
    this.getPosts();
  },
};
</script>
